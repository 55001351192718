body {
  margin: 0;
  height: 100vh;
  /* background-color: #b68484; */
  background-image: url("../public/macos-monterey-wwdc-21-stock-dark-mode-5k-3840x2160-5585.jpg");
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  height: 100%
}

.message {
  border-style: dashed;
  border-width: 2px;
  border-color: #000000;
  width: auto;
  /* height: 100%; */
  margin: 20px;
  text-align: left;
}

* { margin: 0; padding: 0; }
.terminal {
    border-radius: 5px 5px 5px 5px;
    height: 100%;
    position: relative;
}

.terminal-body{
  /* background: blue; */
  height: 80vh;
}
.terminal .top {
    background: #E8E6E8;
    color: black;
    padding: 5px;
    border-radius: 5px 5px 0 0;
}
.terminal .btns {
    position: absolute;
    top: 7px;
    left: 5px;
}
.terminal .circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 15px;
    margin-left: 2px;
    border-width: 1px;
    border-style: solid;
}
.title{
    text-align: center;
}
.red { background: #EC6A5F; border-color: #D04E42; }
.green { background: #64CC57; border-color: #4EA73B; }
.yellow{ background: #F5C04F; border-color: #D6A13D; }
.clear{clear: both;}
.terminal .body {
    background: black;
    color: #7AFB4C;
    padding: 8px;
    overflow: auto;
    height: 100%;
    border-radius: 0 0 5px 5px;
}
.space {
    margin: 25px;
}
.shadow { box-shadow: 0px 0px 10px rgba(0,0,0,.4)}

.divider{
  height: 2px;
  border-width: 1px;
  border-style: dashed;
  border-color: #ffffff;
  margin: 10px;
  margin-left: 22px;
  margin-right: 22px;
}

.stream{
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 80%;
  overflow-y: scroll;
  margin: 15px 20px;
}

.streamItem{
  /* margin: 15px 30px; */
  margin-top: 5px;
  /* padding: 10px; */
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(99, 97, 97, 0.8); 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9); 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(63, 62, 62, 0.4); 
}